<template>
  <v-flex xs12>
    <div class="wapp-main">
      <v-card class="d-flex align-center justify-start mb-2 custom-height">

        <!-- * left users chat cards panel -->

        <div ref="userPanel" class="user-panel">
          <div class="content-area">
            <v-text-field v-model="search" class="mb-2" prepend-inner-icon="mdi-magnify" hide-details single-line
              @input="filterContacts(search)"></v-text-field>
            <div v-if="!filteredChats.length" class="no-chat">
              <div>
                <v-icon>mdi-message-alert-outline</v-icon>
                No conversations to show
              </div>
            </div>
            <div v-for="chat in filteredChats" :key="chat.id" :class="selectedChat.id == chat.id ? 'custom-bg user-card' : 'user-card'
              " @click="getMessages(chat)">
              <div class="user-image">
                <img src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
                  alt="user-image" />
              </div>
              <div class="user-details">
                <div class="u-name">
                  {{ chat.contact && (chat.contact.name || chat.contact.phone) }}
                </div>
                <div class="u-msg">{{ formatFileName(chat.lastMessage) }}</div>
              </div>
              <div class="user-time text-right">
                <div class="u-time">{{ formattedDate(chat.lastUpdated) }}</div>
                <div v-if="chat.newMessageCount > 0" class="u-message-count">
                  {{ chat.newMessageCount }}
                </div>
              </div>
            </div>
          </div>
          <v-btn class="newMessage-fab" fab dark @click="handleNewContactDialog">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>

        <!-- * user chat box -->

        <div ref="mainChatContainer" class="chat-container">
          <div v-if="!selectedChat.id && !isNewChat" class="chat-box">
            <v-container fill-height fill-width class="justify-center">
              <div justify="center" align="center">
                <v-img :src="noAgent" width="200" />
                <h3 class="my-3">No messages to show.</h3>
                <!-- <v-btn color="primarygrad" @click="openBroadCastDialog">
                  <v-icon left>add_circle</v-icon>Add Broadcast
                </v-btn> -->
              </div>
            </v-container>
          </div>
          <!-- * For existing chats -->
          <div v-if="selectedChat.id || isNewChat" ref="chatContainer" class="chat-box">
            <div v-if="selectedChat.id" class="top-head d-flex align-center">
              <v-btn fab dark x-small color="green" class="mr-3 custom-visibilty" @click="controlChatVisibility">
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <div>
                <p class="head-name">
                  {{
                    selectedChat.contact.name
                      ? selectedChat.contact.name
                      : selectedChat.contact.phone
                  }}
                </p>
                <p v-if="selectedChat.contact.name" class="head-contact">
                  {{ selectedChat.contact.phone }}
                </p>
              </div>
            </div>
            <div v-else-if="isNewChat && newContact" class="top-head d-flex align-center">
              <v-btn fab dark x-small color="green" class="mr-3 custom-visibilty" @click="controlChatVisibility">
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <div>
                <p class="head-name">
                  {{
                    newContact.name.length ? newContact.name : newContact.phone
                  }}
                </p>
                <p v-if="newContact.name" class="head-contact">
                  {{ newContact.phone }}
                </p>
              </div>
            </div>

            <!-- * Sent and received message section -->

            <div v-for="(item, index) in wapp.chatHistory" :key="index">
              <div class="date">{{ item.date }}</div>

              <div v-for="message in item.messages" :key="message.id">
                <!-- Text message -->
                <TextMessage :message="message" :icon-color="iconColor" :status="status"
                  :formatted-time="formattedTime" />
                <!-- Template Message -->
                <TemplateMessage :message="message" :icon-color="iconColor" :status="status"
                  :formatted-time="formattedTime" />
                <!-- Image Message -->
                <ImageMessage :message="message" :icon-color="iconColor" :status="status"
                  :formatted-time="formattedTime" />
                <!-- Video Message -->
                <VideoMessage :message="message" :icon-color="iconColor" :status="status"
                  :formatted-time="formattedTime" />
                <!-- Contact -->
                <Contact :message="message" :icon-color="iconColor" :status="status" :formatted-time="formattedTime" />
                <!-- Audio Message -->
                <AudioMessage :message="message" :icon-color="iconColor" :status="status"
                  :formatted-time="formattedTime" />
                <!-- Document -->
                <Document :message="message" :icon-color="iconColor" :status="status" :formatted-time="formattedTime" />
              </div>
            </div>
          </div>

          <!-- * Message typing section -->

          <div v-if="selectedChat.id || isNewChat" class="write-msg">
            <div class="text-area">
              <v-menu ref="menu" top offset-y transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-textarea v-model="wapp.message" class="ma-0 custom-textarea" placeholder="Type here..." rows="1"
                    auto-grow solo append-outer-icon="mdi-send" prepend-icon="mdi-menu" v-bind="attrs"
                    @click:append-outer="sendTextMessages" @keydown.enter.prevent="sendTextMessages"
                    @keydown.shift.enter="insertLineBreak">
                    <template v-slot:prepend>
                      <!-- Here, bind the menu activator events only to the icon -->
                      <v-icon v-on="on" @click="handleTemplateDialog">mdi-menu</v-icon>
                    </template>
                  </v-textarea>
                </template>

                <v-list class="template-menu">
                  <!-- <div v-if="preloader" class="d-flex justify-center align-center">
                                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                    </div> -->
                  <v-list-item v-for="(item, i) in templates" :key="i" class="temp-item">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-icon>
                      <v-hover v-slot="{ hover }" open-delay="100">
                        <v-icon :color="hover ? 'green darken-3' : 'grey darken-1'" @click="sendTemplateMessage(item)">
                          mdi-send-circle
                        </v-icon>
                      </v-hover>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>

          <v-dialog> </v-dialog>
        </div>
        <!-- Contact Details -->
        <!-- <div class="profile-box">
                    <div class="pr-one">
                        <div class="profile-pic">
                            8
                        </div>
                        <div class="contact-number">
                            {{ selectedChat.contact && selectedChat.contact.phone }}
                        </div>
                    </div>
                    <div class="contact-info">
                        <div>
                            <p>Status:</p>
                            <p>Active</p>
                        </div>
                        <div>
                            <p>Last Active:</p>
                            <p>03/12/2024, 12:36</p>
                        </div>
                        <div>
                            <p>Template Messages:</p>
                            <p>4</p>
                        </div>
                    </div>

                    <v-expansion-panels class="mx-1 px-1">
                        <v-expansion-panel class="my-1 rounded-lg">
                            <v-expansion-panel-header class="cs-head">Campaigns</v-expansion-panel-header>
                            <v-expansion-panel-content class="cs-content">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis fugiat quasi eveniet
                                consequatur est voluptatum ipsum sunt architecto magnam, delectus itaque laborum minima
                                provident a! Itaque sint aliquid a quasi?
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="my-1 rounded-lg">
                            <v-expansion-panel-header class="cs-head">Attributes</v-expansion-panel-header>
                            <v-expansion-panel-content class="cs-content">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Id voluptatum aperiam, illo
                                animi impedit omnis, sunt iusto, officiis corrupti sit aliquam earum nam excepturi
                                accusamus perferendis asperiores. Ut, reprehenderit ex!
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="my-1 rounded-lg">
                            <v-expansion-panel-header class="cs-head">Tags</v-expansion-panel-header>
                            <v-expansion-panel-content class="cs-content">
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat dolores dolorum quod
                                iure unde minus expedita odit molestiae. Sed minus odit animi voluptatem dolores quis
                                cumque itaque accusantium, corrupti consequatur!
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="my-1 rounded-lg">
                            <v-expansion-panel-header class="cs-head">Customer Journey</v-expansion-panel-header>
                            <v-expansion-panel-content class="cs-content">
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Commodi laboriosam doloremque
                                est tempora quis aspernatur molestias tenetur sunt earum iusto, rem amet? Nemo ad
                                molestias obcaecati unde numquam! Expedita, laborum.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div> -->
      </v-card>
    </div>

    <!-- * Template dialog for choosing list and number -->

    <v-dialog v-model="openTemplateDialog" transition="dialog-top-transition" persistent width="650">
      <v-card>
        <v-card-title class="primarygrad">Choose Number</v-card-title>
        <div class="pa-3">
          <v-select v-model="sendNewVars.list" :items="lists" item-text="name" item-value="id" label="Choose List"
            return-object @change="getContactsByList"></v-select>
          <v-combobox v-model="sendNewVars.contact" :items="contacts" item-text="phone" item-value="phone" return-object
            label="Choose Number" :filter="customFilter" :clearable="true" :append-icon="'mdi-chevron-down'"
            :menu-props="{ maxHeight: '400' }" placeholder="Choose Number" :disabled="isLoading">
            <template v-slot:item="{ item }">
              <div>
                <div class="text-overline">
                  {{ item.name }}, {{ item.phone }}
                </div>
              </div>
            </template>
          </v-combobox>

          <!-- <v-select v-model="sendTemplate.template" :items="templates" item-text="name" item-value="id"
                        return-object label="Choose Template"></v-select> -->
        </div>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closeTemplateDialog">Cancel</v-btn>
          <v-btn color="primarygrad" @click="handleNewChat">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--  * Common Dialog for all purposes -->

    <v-dialog v-model="initialLoader" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  ! For Testing purpose -->
    <!-- <div>
            <div class="demo">
                <vue-tel-input v-model="wapp.phone" :valid-characters-only="true" class="secondary--text"
                    @input="getPhoneNumber"></vue-tel-input>
                <v-btn color="primary" @click="sendMessages">
                    Send test message template
                </v-btn>
            </div>
            <v-card class="d-flex align-start justify-start px-4 py-4 my-4" color="#4DD0E1">
                <v-icon>{{ icons.mdiAlertBox }}</v-icon>
                <p class="mx-0 px-1 custom-para">
                    Note: If you are using test number, you can only send hello_world template message to pre-approved
                    numbers. Else if you have registered business number you can send hello_world template to any
                    number.
                </p>
            </v-card>
        </div> -->
  </v-flex>
</template>
<script>
import axios from "axios";
// import { mdiCheck, mdiAlertBox } from '@mdi/js';
import { mapGetters } from "vuex";
import Vue from "vue";
import moment from "moment-timezone";
import TextMessage from "./WhatsappMessageFormats/TextMessage.vue";
import TemplateMessage from "./WhatsappMessageFormats/TemplateMessage.vue";
import ImageMessage from "./WhatsappMessageFormats/ImageMessage.vue";
import VideoMessage from "./WhatsappMessageFormats/VideoMessage.vue";
import AudioMessage from "./WhatsappMessageFormats/AudioMessage.vue";
import Contact from "./WhatsappMessageFormats/Contact.vue";
import Document from "./WhatsappMessageFormats/Document.vue";

import io from "socket.io-client";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons/faSlidersH";

export default {
  components: {
    TextMessage,
    TemplateMessage,
    ImageMessage,
    VideoMessage,
    Contact,
    AudioMessage,
    Document,
  },
  props: ["sendMessage", "wappAccess", "godialUser", "sendTextMessage"],
  data: () => ({
    socket: false,
    wapp: {
      phone: "",
      message: "",
      chatHistory: [],
      showSendMessage: false,
    },
    chats: [],
    selectedChat: {},
    templates: [],
    preloader: false,
    openTemplateDialog: false,
    lists: [],
    contacts: [],
    sendNewVars: {
      list: null,
      contact: null,
    },
    search: "",
    isLoading: false,
    filteredChats: [],
    isNewChat: false,
    newContact: null,
    screenWidth: window.innerWidth,
    mobileChatVisible: false,
    initialLoader: false,
    // icons: {
    //     mdiCheck,
    //     mdiAlertBox
    // },
  }),
  computed: {
    ...mapGetters(["ENDPOINT", "user", "companyDetails", "selectedTeam"]),
    formattedTime() {
      return (timeString) => {
        const parsedDate = moment(timeString);
        const hours = parsedDate.format("hh");
        const minutes = parsedDate.format("mm");
        const amPm = parsedDate.format("a");
        return `${hours}:${minutes} ${amPm}`;
      };
    },
    noAgent() {
      return require("../assets/noAgent.png");
    },
    status() {
      return (msgStatus) => {
        switch (msgStatus) {
          case "pending":
            return "mdi-update"; // Example icon for active status
          case "sent":
            return "mdi-check"; // Example icon for inactive status
          case "delivered":
            return "mdi-check-all"; // Example icon for inactive status
          case "read":
            return "mdi-check-all"; // Example icon for inactive status
          case "failed":
            return "mdi-help-circle"; // Default icon
          default:
            return "mdi-help-circle"; // Default icon
        }
      };
    },

    iconColor() {
      return (msgStatus) => {
        return msgStatus != "read"
          ? msgStatus == "failed"
            ? "text-red"
            : "text-grey"
          : "text-blue";
      };
    },
  },
  watch: {
    "wapp.chatHistory": function (newVal, oldVal) {
      this.scrollToBottom();
    },
  },
  async mounted() {
    this.updateScreenWidth();
    window.addEventListener("resize", this.updateScreenWidth);

    const domain = new URL(process.env.VUE_APP_ENDPOINT).origin;
    console.log("Socket ENV", domain);
    //Staging https://staging.godial.cc
    //Production
    this.socket = io(domain, {
      // path: "/meta/socket.io",
      transports: ["websocket"],

      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 30000, // 20 seconds before considering a connection attempt as failed
    }); // Adjust the URL to your server's

    this.socket.on("connect", function () {
      console.log("Connected to SOCKET");
    });

    this.socket.on("connect_error", (error) => {
      console.log("Connection Error:", error);
    });

    this.socket.on("connect_timeout", (timeout) => {
      console.log("Connection Timeout:", timeout);
    });
    // For getting conversations updates
    this.socket.on("new_conversation", async (conversation) => {
      // console.log("new conversation coming", conversation);
      const convs = await this.$http.get(
        `${self.ENDPOINT}/conversations/${conversation.id}?filter[where][companyId]=${this.user.companyId}&filter[include]=contact&filter[order]=lastUpdated%20DESC`
      );
      // this.chats = convs.body;

      console.log("Updated conversation: ", convs.body);

      this.updateOrAddItem(this.chats, convs.body);

      this.chats.map(async (item) => {
        // console.log("Listning to ", `conversation_${item.id}`)

        //TODO push the message directly without calling server later
        this.socket.on(`conversation_${item.id}`, async (message) => {
          console.log("Incoming message", message);

          // Updating and sorting conversations
          const convs = await this.$http.get(
            `${self.ENDPOINT}/conversations/${message.conversationId}?filter[include]=contact&filter[order]=lastUpdated%20DESC`
          );
          // this.chats = convs.body;

          console.log("Updated conversation: ", convs.body);

          this.updateOrAddItem(this.chats, convs.body);
          if (message.conversationId == convs.body.id) {
            //Then updating message
            const messageDate = this.convertDateFormat(message.sentAt);
            const lastEntry = this.wapp.chatHistory[
              this.wapp.chatHistory.length - 1
            ];
            // self.getMessages({ id: message.conversationId })
            if (lastEntry && lastEntry.date === messageDate) {
              // Check if the message already exists
              if (!lastEntry.messages.some((obj) => obj.id === message.id)) {
                // If the message does not exist, push the new message
                lastEntry.messages.push(message);
              } else {
                // If the message exists, update the status
                const existingMessage = lastEntry.messages.find(
                  (obj) => obj.id === message.id
                );
                existingMessage.status = message.status;
              }
            } else {
              // If the last entry date does not match or doesn't exist, create a new entry
              this.wapp.chatHistory.push({
                date: messageDate,
                messages: [message],
              });
            }
          }
        });
      });
    });

    this.initialLoader = true;

    // Getting all conversations against company id
    const convs = await this.$http.get(
      `${this.ENDPOINT}/conversations?filter[where][companyId]=${this.user.companyId}&filter[include]=contact&filter[order]=lastUpdated%20DESC`
    );
    // console.log("Conversations:  ", convs.body);
    this.chats = convs.body;

    console.log("this.chats: ", this.chats);
    const self = this;
    //For getting message updates
    this.chats.map(async (item) => {
      // console.log("Listning to ", `conversation_${item.id}`)

      //TODO push the message directly without calling server later
      this.socket.on(`conversation_${item.id}`, async (message) => {
        console.log("Incoming message", message);

        // Updating and sorting conversations
        const convs = await this.$http.get(
          `${self.ENDPOINT}/conversations/${message.conversationId}?filter[include]=contact&filter[order]=lastUpdated%20DESC`
        );
        // this.chats = convs.body;

        console.log("Updated conversation: ", convs.body);

        this.updateOrAddItem(this.chats, convs.body);

        if (message.conversationId == this.selectedChat.id) {
          //Then updating message
          const messageDate = this.convertDateFormat(message.sentAt);
          const lastEntry = this.wapp.chatHistory[
            this.wapp.chatHistory.length - 1
          ];
          // self.getMessages({ id: message.conversationId })
          if (lastEntry && lastEntry.date === messageDate) {
            // Check if the message already exists
            if (!lastEntry.messages.some((obj) => obj.id === message.id)) {
              // If the message does not exist, push the new message
              lastEntry.messages.push(message);
            } else {
              // If the message exists, update the status
              const existingMessage = lastEntry.messages.find(
                (obj) => obj.id === message.id
              );
              existingMessage.status = message.status;
            }
          } else {
            // If the last entry date does not match or doesn't exist, create a new entry
            this.wapp.chatHistory.push({
              date: messageDate,
              messages: [message],
            });
          }
        }
      });
    });
    // console.log("Conversations: ", this.chats);

    // console.log("Send templates: ", this.sendTemplate);

    this.filteredChats = this.chats.filter(chat => chat.contact !== undefined);

    this.getTemplates();

    this.initialLoader = false;

    // this.filterContacts(this.search);
  },
  updated() {
    this.scrollToBottom();
  },
  beforeDestroy() {
    this.socket.close();
  },
  methods: {
    insertLineBreak(event) {
      // Insert a line break at the cursor position
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      // Add a new line at the cursor position
      this.wapp.message =
        this.wapp.message.substring(0, start) + "\n" + this.wapp.message.substring(end);

      // Set the cursor position after the new line
      this.$nextTick(() => {
        textarea.selectionStart = textarea.selectionEnd = start + 1;
      });
    },
    controlChatVisibility() {
      this.mobileChatVisible = !this.mobileChatVisible;
      this.applyChatVisibility();
      this.hideVisibility();
    },
    hideVisibility() {
      console.log("hidevisibility fired");
      const panel = this.$refs.userPanel; // Assume you have a ref="chatBox" on the chat container
      if (this.mobileChatVisible) {
        panel.style.display = "none";
      } else {
        panel.style.display = "flex";
      }
    },
    applyChatVisibility() {
      console.log("applyvisibility fired");
      const chatBox = this.$refs.mainChatContainer; // Assume you have a ref="chatBox" on the chat container
      if (this.mobileChatVisible) {
        chatBox.style.display = "block";
      } else {
        chatBox.style.display = "none";
      }
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
      console.log("screen width: ", this.screenWidth);
    },
    async handleNewContactDialog() {
      await this.fetchListByTeam();
      this.openTemplateDialog = true;
    },
    async handleNewChat() {
      this.selectedChat = {};
      this.wapp = {
        phone: "",
        message: "",
        chatHistory: [],
        showSendMessage: false,
      };
      console.log("this.selectedChat", this.selectedChat);
      console.log("data Contact: ", this.sendNewVars.contact);
      const data = {
        contactObj: this.sendNewVars.contact,
      };
      const contact = await this.$http.post(
        `${this.ENDPOINT}/whatsapps/addContactToWp`,
        data
      );
      console.log("Contact body: ", contact.body);
      const isExist = this.filteredChats.find(
        (chat) => chat.contact.phone == contact.body.phone
      );
      console.log("isExist: ", isExist);
      if (isExist) {
        // this.selectedChat = isExist;
        await this.getMessages(isExist);
        console.log("selectedChat: ", this.selectedChat);
      } else {
        this.isNewChat = true;
        this.newContact = contact.body;
        if (this.screenWidth <= 560) {
          this.controlChatVisibility();
        }
        console.log("new chat created", this.isNewChat, this.newContact);
      }
      this.closeTemplateDialog();
    },
    filterContacts(searchText) {
      const lowercasedSearchText = searchText.toLowerCase();
      console.log("lowercasedSearchText", lowercasedSearchText);
      this.filteredChats = this.chats.filter((contactObj) => {
        if (contactObj.contact != undefined) {
          const { contact, lastMessage } = contactObj;
          const { name, phone } = contact;
          // Ensure all text fields are treated as strings and converted to lowercase
          const nameMatch = name.toLowerCase().includes(lowercasedSearchText);
          const phoneMatch = String(phone)
            .toLowerCase()
            .includes(lowercasedSearchText); // Convert phone to string
          const messageMatch = lastMessage
            ? lastMessage.toLowerCase().includes(lowercasedSearchText)
            : false;

          // Logging for debugging
          console.log(
            "Name match: ",
            nameMatch,
            "Phone Match: ",
            phoneMatch,
            "Message Match: ",
            messageMatch
          );

          // Return true if any of the fields match the search text
          return nameMatch || phoneMatch || messageMatch;
        }

      });

      console.log("Filtered Chats: ", this.filteredChats);
    },
    updateOrAddItem(items, newItem) {
      // Find the index of the item with the same ID
      const index = items.findIndex((item) => item.id === newItem.id);

      if (index !== -1) {
        // Replace the item if found
        items[index] = newItem;
      } else {
        // Add new item if not found
        items.push(newItem);
      }

      // Sort the array by `lastUpdated` in descending order
      items.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));

      this.filteredChats = items.filter(chat => chat.contact !== undefined);

      return items;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          const container = this.$refs.chatContainer;
          if (container) {
            container.scrollTop = container.scrollHeight;
          }
        }, 100); // Adjust delay as necessary
      });
    },
    convertDateFormat(dateString) {
      return moment(dateString).format("DD/MM/YYYY");
    },
    customFilter(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchText) ||
        item.phone.toLowerCase().includes(searchText)
      );
    },
    formatFileName(fileName) {
      if (fileName && fileName.length > 15) {
        return fileName.substring(0, 15) + "..."; // Adds an ellipsis if longer than 15 characters
      }
      return fileName; // Returns the original filename if it's 15 characters or shorter
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      return `${day} ${month}`;
    },

    async handleTemplateDialog() {
      console.log("handle menu fired");
      try {
        // this.preloader = true;
        // await this.getTemplates();  // Wait for the templates to load successfully
        console.log("templates: ", this.templates);
        console.log(this.$refs.menu);
        this.$nextTick(() => {
          this.$refs.menu.isActive = true;
        });
        // this.preloader = false;
      } catch (error) {
        console.log(
          "Error loading templates, menu will not be activated:",
          error
        );
        // Optionally, handle any further actions like notifying user of error
      }
    },

    closeTemplateDialog() {
      this.sendTemplate = {
        list: null,
        contact: null,
      };
      this.openTemplateDialog = false;
    },

    async sendTemplateMessage(item) {
      console.log("item: ", item);
      if (this.companyDetails.whatsappCredits > 0) {
        // If it's not a new chat
        if (!this.isNewChat) {
          try {
            const data = {
              contact: this.selectedChat.contact,
              template: item,
            };
            console.log("data: ", data);
            const response = await this.$http.post(
              `${this.ENDPOINT}/whatsapps/sendTemplateMessage`,
              data
            );
            console.log("response: ", response.body);
          } catch (error) {
            this.$swal({
              type: "error",
              text: "An error occured while sending message",
            });
          }
        } else { // If it's a new chat
          try {
            const data = {
              contact: this.newContact,
              template: item,
            };
            console.log("data: ", data);
            const response = await this.$http.post(
              `${this.ENDPOINT}/whatsapps/sendTemplateMessage`,
              data
            );
            console.log("response send template message: ", response.body);
            // this.$swal({
            //     type: "success",
            //     text: "Message successfully changed",
            // });
            // const convs = await this.$http.get(
            //   `${self.ENDPOINT}/conversations/${response.body.conversationId}?filter[include]=contact&filter[order]=lastUpdated%20DESC`
            // );

            // console.log("new chat convs: ", convs.body);

            // await this.getMessages(convs.body);
            const filteredContacts = this.chats.filter(chat => chat.contact.phone === response.body.contacts[0].input);
            console.log("filteredContacts: ", filteredContacts);
            this.selectedChat = filteredContacts[0];
            this.isNewChat = false;
            this.newContact = null;
          } catch (error) {
            this.$swal({
              type: "error",
              text: "An error occured while sending message",
            });
          }
        }
      } else {
        this.$swal({
          type: "error",
          text: "Not enough credits! Please buy more credits to continue",
        });
      }

      this.$store.dispatch("getCompanyDetails", {
        companyId: this.user.companyId,
      });
    },

    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },

    async fetchListByTeam() {
      const self = this;
      try {
        var teamsId = self.getTeamIds();
        var response = await self.$http.post(`${self.ENDPOINT}/lists/fetch`, {
          teams: teamsId,
        });
        self.lists = response.body;
      } catch (e) {
        console.log(e);
      }
    },

    async getContactsByList() {
      this.isLoading = true;
      try {
        var response = await this.$http.get(
          `${this.ENDPOINT}/lists/${this.sendNewVars.list.id}/contacts`
        );
        this.contacts = response.body;
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },

    getTemplates() {
      this.preloader = true;
      this.templates = [];
      // Return the Promise chain
      return this.$http
        .get(this.$store.state.ENDPOINT + "/whatsapps/getTemplates")
        .then((response) => {
          this.preloader = false;
          response = response.body;
          if (response && response.data && Array.isArray(response.data)) {
            this.templates = _.filter(response.data, { status: "APPROVED" });
            console.log("Loaded templates:", this.templates);
          } else {
            throw new Error(
              "Invalid response format while loading the templates."
            );
          }
        })
        .catch((error) => {
          this.preloader = false;
          console.error("Failed to load templates:", error);
          this.$swal({
            type: "error",
            text:
              "Something went wrong while loading the templates: " +
              error.message,
          });
          throw error; // Rethrow to keep the error propagating
        })
        .finally(() => {
          this.preloader = false; // Ensure preloader is turned off
        });
    },

    // formattedTime(timeString) {
    //     const parsedDate = moment(timeString);
    //     const hours = parsedDate.format('hh');
    //     const minutes = parsedDate.format('mm');
    //     const amPm = parsedDate.format('A');
    //     this.formattedTime = `${hours}:${minutes} ${amPm}`;
    // },
    // async sendTextMessages($event) {
    //   $event.preventDefault();
    //   console.time("sendTextMessages");
    //   console.log("Timing started");
    //   this.initialLoader = true;
    //   if (this.companyDetails.whatsappCredits > 0) {
    //     if (!this.isNewChat) {
    //       const message = this.wapp.message;
    //       this.wapp.message = "";
    //       const data = {
    //         number: this.selectedChat.contact.phone,
    //         message: message.replace(/\n/g, ""),
    //       };
    //       const sendingData = await this.$http.post(
    //         `${this.ENDPOINT}/whatsapps/sendTextMessage`,
    //         data
    //       );
    //       console.log("sendingData", sendingData.body);
    //     } else {
    //       const message = this.wapp.message;
    //       this.wapp.message = "";
    //       const data = {
    //         number: this.newContact.phone,
    //         message: message.replace(/\n/g, ""),
    //       };
    //       const sendingData = await this.$http.post(
    //         `${this.ENDPOINT}/whatsapps/sendTextMessage`,
    //         data
    //       );
    //       console.log("sendingData", sendingData.body);
    //       const convs = await this.$http.get(
    //         `${self.ENDPOINT}/conversations/${sendingData.body.conversationId}?filter[include]=contact&filter[order]=lastUpdated%20DESC`
    //       );

    //       await this.getMessages(convs.body);

    //       this.isNewChat = false;
    //       this.newContact = null;
    //     }
    //   } else {
    //     this.$swal({
    //       type: "error",
    //       text: "Not enough credits! Please buy more credits to continue",
    //     });
    //   }
    //   this.$store.dispatch("getCompanyDetails", {
    //     companyId: this.user.companyId,
    //   });
    //   this.initialLoader = false;
    //   console.timeEnd("sendTextMessages");
    // },
    async sendTextMessages($event) {
      $event.preventDefault();
      console.time("sendTextMessages");

      if ($event.shiftKey) {
        // If Shift is pressed, we allow the default behavior for line breaks
        return;
      }

      if (this.companyDetails.whatsappCredits > 0) {
        const message = this.wapp.message;
        console.log("message sending: ", message);
        const isNewChat = this.isNewChat;
        const chatContact = isNewChat ? this.newContact : this.selectedChat.contact;

        // Clear the message input after sending
        this.wapp.message = "";

        // Optimistically update the UI
        const tempMessage = {
          id: Date.now(), // Temporary ID
          content: message, // Keep the \n in the message
          sentAt: new Date().toISOString(),
          senderType: "user",
          status: "pending", // Show as pending until the response is received
        };

        const messageDate = new Date().toLocaleDateString();
        const lastEntry = this.wapp.chatHistory[this.wapp.chatHistory.length - 1];

        if (lastEntry && lastEntry.date === messageDate) {
          lastEntry.messages.push(tempMessage);
        } else {
          this.wapp.chatHistory.push({
            date: messageDate,
            messages: [tempMessage],
          });
        }

        this.scrollToBottom();

        // Send the API call in the background
        setTimeout(async () => {
          const data = {
            number: chatContact.phone,
            message: tempMessage.content, // Send message with \n
          };

          try {
            const sendingData = await this.$http.post(
              `${this.ENDPOINT}/whatsapps/sendTextMessage`,
              data
            );

            console.log("sendingData", sendingData.body);

            // Update the message status and ID with the response data
            tempMessage.id = sendingData.body.id;
            tempMessage.status = "sent";

            if (isNewChat) {
              const convs = await this.$http.get(
                `${this.ENDPOINT}/conversations/${sendingData.body.conversationId}?filter[include]=contact&filter[order]=lastUpdated%20DESC`
              );

              this.updateOrAddItem(this.chats, convs.body);
              await this.getMessages(convs.body);

              this.isNewChat = false;
              this.newContact = null;
            }
          } catch (error) {
            console.log("Error sending message:", error);

            // Handle failure (e.g., show an error message or change the message status)
            tempMessage.status = "failed";
          } finally {
            this.initialLoader = false;
            this.$store.dispatch("getCompanyDetails", {
              companyId: this.user.companyId,
            });
          }
        }, 0);
      } else {
        this.$swal({
          type: "error",
          text: "Not enough credits! Please buy more credits to continue",
        });
      }

      console.timeEnd("sendTextMessages");
    },


    getPhoneNumber(e, phone) {
      if (e) {
        return (this.wapp.phone = phone.number.e164);
      } else {
        return (this.wapp.phone = "");
      }
    },
    // async getMessages(conversation) {
    //   // Vue.set(this.selectedChat, 'selectedChat', conversation);
    //   const self = this;

    //   try {
    //     this.selectedChat = conversation;
    //     // Look for conversation's new message count if it's > 0 make it 0
    //     if (conversation.newMessageCount > 0) {
    //       // first patch it then get conversation cause filter won't work
    //       const updatedConv = await self.$http.patch(
    //         `${self.ENDPOINT}/conversations/${conversation.id}`,
    //         {
    //           newMessageCount: 0,
    //         }
    //       );
    //       // Getting the conv with contact
    //       const getconv = await self.$http.get(
    //         `${self.ENDPOINT}/conversations/${conversation.id}?filter[include]=contact&filter[order]=lastUpdated%20DESC`
    //       );

    //       console.log("updated conv", getconv.body);
    //       this.updateOrAddItem(this.chats, getconv.body);
    //       console.log("this.chats:", this.chats);
    //     }
    //     this.selectedChat = conversation;
    //     const messages = await self.$http.get(
    //       `${self.ENDPOINT}/messages?filter[where][conversationId]=${conversation.id}`
    //     );

    //     // Set showSendMessage

    //     this.wapp.showSendMessage = messages.body.some(
    //       (item) => item.senderType === "contact"
    //     );

    //     const messagesByDateArray = [];

    //     messages.body.forEach((message) => {
    //       // Extract the date from the message
    //       const sentAt = new Date(message.sentAt).toLocaleDateString();

    //       // Find the index of the date in messagesByDateArray
    //       const index = messagesByDateArray.findIndex(
    //         (item) => item.date === sentAt
    //       );

    //       // If the date is not found, add it to messagesByDateArray
    //       if (index === -1) {
    //         messagesByDateArray.push({ date: sentAt, messages: [message] });
    //       } else {
    //         // If the date already exists, push the message to its array
    //         messagesByDateArray[index].messages.push(message);
    //       }
    //     });

    //     messagesByDateArray.map((item) =>
    //       item.messages.sort((a, b) => new Date(a.sentAt) - new Date(b.sentAt))
    //     );

    //     Vue.set(this.wapp, "chatHistory", messagesByDateArray);
    //     console.log("Messages:, ", self.wapp.chatHistory);
    //     if (this.screenWidth <= 560) {
    //       this.controlChatVisibility();
    //     }
    //     this.scrollToBottom();
    //   } catch (error) {
    //     console.log("Get message error: ", error);
    //   }
    // },

    async getMessages(conversation) {
      const self = this;

      try {
        this.selectedChat = conversation;

        // Fetch both conversation update and messages in parallel
        const [updatedConv, messagesResponse] = await Promise.all([
          conversation.newMessageCount > 0
            ? self.$http.patch(`${self.ENDPOINT}/conversations/${conversation.id}`, { newMessageCount: 0 })
            : Promise.resolve(null),
          self.$http.get(`${self.ENDPOINT}/messages?filter[where][conversationId]=${conversation.id}`)
        ]);

        if (updatedConv) {
          const getconv = await self.$http.get(
            `${self.ENDPOINT}/conversations/${conversation.id}?filter[include]=contact&filter[order]=lastUpdated%20DESC`
          );
          console.log("updated conv", getconv.body);
          this.updateOrAddItem(this.chats, getconv.body);
          console.log("this.chats:", this.chats);
        }

        const messages = messagesResponse.body;

        this.wapp.showSendMessage = messages.some(
          (item) => item.senderType === "contact"
        );

        const messagesByDateArray = [];

        messages.forEach((message) => {
          const sentAt = new Date(message.sentAt).toLocaleDateString();

          const index = messagesByDateArray.findIndex(
            (item) => item.date === sentAt
          );

          if (index === -1) {
            messagesByDateArray.push({ date: sentAt, messages: [message] });
          } else {
            messagesByDateArray[index].messages.push(message);
          }
        });

        messagesByDateArray.forEach((item) =>
          item.messages.sort((a, b) => new Date(a.sentAt) - new Date(b.sentAt))
        );

        Vue.set(this.wapp, "chatHistory", messagesByDateArray);
        console.log("Messages:, ", self.wapp.chatHistory);
        if (this.screenWidth <= 560) {
          this.controlChatVisibility();
        }
        this.scrollToBottom();
      } catch (error) {
        console.log("Get message error: ", error);
      }
    }
  },
};
</script>
<style scoped>
.bg-white {
  background-color: white;
}

.bg-blue {
  background-color: #dcf8c6;
  color: black;
}

.chat-container {
  width: 70%;
  /* max-width: 100%; */
  height: 100%;
  background-color: rgb(243, 243, 243);
  /* border: 1px solid black; */
  /* padding-top: 0.5rem; */
  padding-bottom: 7rem;
  position: relative;
}

.chat-alignment {
  overflow-y: auto;
  /* Use auto to show scrollbar only when needed */
  /* margin-bottom: 1rem; */
  max-height: 100%;
}

.cs-head {
  font-size: 0.8rem;
}

.cs-content {
  font-size: 0.7rem;
}

.contact-info {
  /* From https://css.glass */
  width: 95%;
  background: rgba(198, 255, 250, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem;
  margin: 0.5rem;
}

.contact-info div {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
}

.contact-info div p {
  margin: 0 0.5rem 0 0;
}

.custom-visibilty {
  display: none;
}

.date {
  text-align: center;
  font-size: 0.7rem;
  background-color: burlywood;
  border-radius: 9px;
  width: 30%;
  margin: auto;
}

.content-area {
  overflow-y: auto;
  /* Makes this area scrollable */
  flex-grow: 1;
  /* scrollbar-width: thin;
    scrollbar-color: #b0b0b0 #f0f0f0; */
  /* thumb and track color */
}

/* Styles for the scrollbar track (part the thumb slides within) */
.content-area::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Light gray track for better contrast */
  border-radius: 10px;
  /* Rounded corners for the track */
}

/* Styles for the scrollbar thumb (draggable part) */
.content-area::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  /* Darker gray thumb for visibility */
  border-radius: 10px;
  /* Rounded corners on the thumb */
  border: 2px solid #f0f0f0;
  /* Slight border to create padding effect within the track */
}

/* Styles for the scrollbar itself (including width) */
.content-area::-webkit-scrollbar {
  width: 8px;
  /* Adjusted width for better grip and visibility */
  height: 8px;
  /* For horizontal scrollbars, if needed */
}

/* Hover effect for the scrollbar thumb */
.content-area::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
  /* Lighter gray when hovered for interactive feel */
}

.chat-box {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  overflow-y: auto;
  margin-bottom: 2rem;
  padding: 0;
  /* Ensure there's no unexpected padding */
  margin: 0;
  position: relative;
  /* scrollbar-width: thin;
    scrollbar-color: #b0b0b0 #f0f0f0; */
  /* thumb and track color */
}

/* Styles for the scrollbar track (part the thumb slides within) */
.chat-box::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Light gray track for better contrast */
  border-radius: 10px;
  /* Rounded corners for the track */
}

/* Styles for the scrollbar thumb (draggable part) */
.chat-box::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  /* Darker gray thumb for visibility */
  border-radius: 10px;
  /* Rounded corners on the thumb */
  border: 2px solid #f0f0f0;
  /* Slight border to create padding effect within the track */
}

/* Styles for the scrollbar itself (including width) */
.chat-box::-webkit-scrollbar {
  width: 8px;
  /* Adjusted width for better grip and visibility */
  height: 8px;
  /* For horizontal scrollbars, if needed */
}

/* Hover effect for the scrollbar thumb */
.chat-box::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
  /* Lighter gray when hovered for interactive feel */
}

.chat-recv-msg {
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 8px;
  /* margin-left: 0.5rem; */
  max-width: 60%;
}

.custom-height {
  height: 80vh;
}

.contact-number {
  font-size: 1rem;
}

.custom-para {
  margin: 0;
  font-size: 0.9rem;
}

.custom-textarea {
  max-height: 150px;
  width: 100%;
  /* Adjust this value according to your requirement */
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.2rem;
  box-shadow: none;
}

.custom-bg {
  background-color: #d7d7d7;
}

.demo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 1rem;
}

.fb-button {
  color: white;
}

.head-name {
  font-weight: 500;
  font-size: 0.9rem;
  margin: 0;
}

.head-contact {
  font-size: 0.7rem;
  margin: 0;
}

.intframe {
  height: 100vh;
  width: 100%;
}

.no-chat {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newMessage-fab {
  background: linear-gradient(to right, #92df48, #1bca3f);
  position: sticky;
  /* Makes the button stick to the bottom of the panel */
  bottom: 1rem;
  /* Distance from the bottom of the user panel */
  right: 1rem;
  /* Distance from the right of the user panel */
  z-index: 2;
  /* Ensures it's above other content */
  align-self: flex-end;
  /* Aligns the button to the right */
}

.pr-one {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-inline: 1rem;
}

/* .profile-box {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding-block: 1rem;
} */

.profile-pic {
  width: 3rem;
  height: 3rem;
  background-color: aquamarine;
  color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.small-chat-img {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  /* margin-left: 0.2rem; */
}

.small-chat-img img {
  width: 100%;
  /* Ensures the image fills the container horizontally */
  height: 100%;
  /* Ensures the image fills the container vertically */
  object-fit: cover;
  /* Scales the image while preserving aspect ratio */
  object-position: center;
  /* Centers the image within the container */
  position: absolute;
  /* Allows the image to be positioned within the container */
  top: 0;
  /* Aligns the top of the image with the top of the container */
  left: 0;
  /* Aligns the left of the image with the left of the container */
}

.secondary--text {
  width: 60%;
}

.text-blue {
  color: #1877f2;
  font-size: 0.7rem;
}

.text-grey {
  color: #808080;
  font-size: 0.7rem;
}

.text-red {
  color: #ff3c3c;
  font-size: 0.7rem;
}

.time {
  font-size: 0.5rem;
}

.top-head {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0.2rem 0.5rem;
  background-color: rgb(214, 214, 214);
  z-index: 20;
  margin-bottom: 1rem;
}

.temp-item:hover {
  background-color: #d7d7d7;
  cursor: pointer;
}

.template-menu {
  max-height: 25rem;
  overflow-y: auto;
}

.user-time {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.u-time {
  font-size: 0.6rem;
  color: rgb(101, 101, 101);
}

.user-details {
  /* margin-left: 1rem; */
  width: 70%;
  padding-left: 0.5rem;
}

.u-icon {
  width: 1rem;
  height: auto;
}

.u-name {
  font-weight: 600;
}

.u-msg {
  font-size: 0.8rem;
}

.user-image {
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.user-image img {
  width: 100%;
  /* Ensures the image fills the container horizontally */
  height: 100%;
  /* Ensures the image fills the container vertically */
  object-fit: cover;
  /* Scales the image while preserving aspect ratio */
  object-position: center;
  /* Centers the image within the container */
  position: absolute;
  /* Allows the image to be positioned within the container */
  top: 0;
  /* Aligns the top of the image with the top of the container */
  left: 0;
  /* Aligns the left of the image with the left of the container */
}

.user-card {
  padding: 0.5rem;
  width: 100%;
  border-bottom: 0.5px solid rgb(194, 194, 194);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.user-card:hover {
  background-color: #d7d7d7;
  cursor: pointer;
}

.user-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Align children to start and end */
  height: 100%;
  width: 30%;
  border-right: 0.5px solid rgb(174, 174, 174);
  padding: 0 0.5rem;
  border-radius: 0 !important;
  position: relative;
  overflow: hidden;
  /* Ensures no overflow outside the panel */
}

.u-message-count {
  background-color: #1bca3f;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  color: white;
  font-size: 0.7rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.write-msg {
  position: absolute;
  bottom: 0;
  /* background-color: white; */
  width: 100%;
  /* height: 2.5rem; */
  overflow: hidden;
  padding: 0 0.5rem;
}

.wapp-main {
  position: relative;
  width: 100%;
  height: 100%;
  /* padding-bottom: 2rem; */
}

@media only screen and (min-width: 820px) and (max-width: 990px) {
  .user-image {
    width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
  }

  .u-name {
    font-weight: 600;
    font-size: 0.8em;
  }

  .u-msg {
    font-size: 0.6em;
  }
}

@media only screen and (min-width: 685px) and (max-width: 820px) {
  .user-panel {
    width: 45%;
  }

  .profile-box {
    display: none;
  }

  .chat-container {
    width: 55%;
  }

  /* .user-image {
        width: 2.5rem;
        height: 2.5rem;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
    }

    .u-name {
        font-weight: 600;
        font-size: 0.8em;
    }

    .u-msg {
        font-size: 0.6em;
    } */
}

@media only screen and (min-width: 560px) and (max-width: 685px) {
  .user-panel {
    width: 45%;
  }

  .profile-box {
    display: none;
  }

  .chat-container {
    width: 55%;
  }

  .user-image {
    width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
  }

  .u-name {
    font-weight: 600;
    font-size: 0.8em;
  }

  .u-msg {
    font-size: 0.6em;
  }
}

/* Mobile screens or lower */

@media only screen and (max-width: 560px) {
  .chat-container {
    width: 100%;
    display: none;
  }

  .custom-visibilty {
    display: flex;
  }

  .user-panel {
    width: 100%;
    border: none;
  }

  .profile-box {
    display: none;
  }

  .user-details {
    width: 70%;
    margin-left: 0.5rem;
  }

  .user-card {
    padding: 0.5rem;
    width: 100%;
    border-bottom: 0.5px solid rgb(194, 194, 194);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .user-image {
    width: 1.5rem;
    height: 1.5rem;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
  }

  .u-name {
    font-weight: 600;
    /* font-size: 0.8em; */
  }

  .u-msg {
    font-size: 0.8em;
  }
}
</style>
