<template>
  <div v-if="message.messageType == 'text'">
    <!-- received message  -->
    <div v-if="message.senderType == 'contact'" class="d-flex align-end justify-start mx-2 my-2">
      <div class="small-chat-img mr-2">
        <img src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
          alt="user" />
      </div>
      <div class="chat-recv-msg bg-white">
        <div v-html="formattedMessageContent"></div> <!-- Use v-html to render formatted content -->
        <div class="time ta-r">{{ formattedTime(message.sentAt) }}</div>
      </div>
    </div>
    <!-- Sent message  -->
    <div v-if="message.senderType == 'user'" class="d-flex align-end justify-end my-2">
      <div class="chat-recv-msg bg-blue">
        <div v-html="formattedMessageContent"></div> <!-- Use v-html to render formatted content -->
        <div class="d-flex justify-end align-center min-width mt-2">
          <div class="time mr-1">{{ formattedTime(message.sentAt) }}</div>
          <template v-if="message.status === 'failed' && message.error">
            <v-tooltip top color="#E53935" max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-icon :class="iconColor(message.status)" v-bind="attrs" v-on="on">
                  {{ status(message.status) }}
                </v-icon>
              </template>
              <span class="custom-tooltip">{{ message.error[0].title }}</span>
            </v-tooltip>
          </template>
          <v-icon v-else :class="iconColor(message.status)">
            {{ status(message.status) }}
          </v-icon>
        </div>
      </div>
      <div class="small-chat-img mx-2">
        <img src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
          alt="user" />
      </div>
    </div>
  </div>
</template>
<script>
import DOMPurify from 'dompurify';
export default {
  components: {},
  props: ["message", "iconColor", "status", "formattedTime"],
  computed: {
    formattedMessageContent() {
      // Check if the content is a string, then replace '\n' with '<br>'
      if (typeof this.message.content === 'string') {
        const rawHtml = this.message.content.replace(/\n/g, '<br>');
        // Sanitize the HTML using DOMPurify
        return DOMPurify.sanitize(rawHtml);
      }
      return this.message.content;

    }
  },
  mounted() {
    console.log("message: ", this.message)
    console.log("message type: ", typeof this.message.content)
  },
};
</script>
<style scoped>
.bg-white {
  background-color: white;
}

.bg-blue {
  background-color: #dcf8c6;
  color: black;
}

.chat-recv-msg {
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 8px;
  /* margin-left: 0.5rem; */
  max-width: 60%;
}

.custom-tooltip {
  font-size: 0.7rem;
  text-wrap: wrap;
  width: 10rem;
}

.min-width {
  min-width: 4rem;
}

.small-chat-img {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  /* margin-left: 0.2rem; */
}

.small-chat-img img {
  width: 100%;
  /* Ensures the image fills the container horizontally */
  height: 100%;
  /* Ensures the image fills the container vertically */
  object-fit: cover;
  /* Scales the image while preserving aspect ratio */
  object-position: center;
  /* Centers the image within the container */
  position: absolute;
  /* Allows the image to be positioned within the container */
  top: 0;
  /* Aligns the top of the image with the top of the container */
  left: 0;
  /* Aligns the left of the image with the left of the container */
}

.text-blue {
  color: #1877f2;
  font-size: 0.7rem;
}

.text-grey {
  color: #808080;
  font-size: 0.7rem;
}

.text-red {
  color: #ff3c3c;
  font-size: 0.7rem;
}

.ta-r {
  text-align: right;
}

.time {
  font-size: 0.6rem;
}
</style>
